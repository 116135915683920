import { render, staticRenderFns } from "./JourneyItem.vue?vue&type=template&id=f790d3b2&scoped=true&"
import script from "./JourneyItem.vue?vue&type=script&lang=js&"
export * from "./JourneyItem.vue?vue&type=script&lang=js&"
import style0 from "./JourneyItem.vue?vue&type=style&index=0&id=f790d3b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f790d3b2",
  null
  
)

export default component.exports