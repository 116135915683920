<template>
  <div class="page">
    <Content>
      <FlexContainer padding="normal" class="journeys__header">
        <FlexItem
          :grow="true"
          class="journeys__description"
          padding-horizontal="normal"
        >
          <div v-html="journeysMetadata.journeysDescription" />
        </FlexItem>
        <FlexItem size="66.6%" padding-horizontal="normal">
          <img
            class="journeys__header-image"
            :src="journeysMetadata.journeyHeaderImage"
          />
        </FlexItem>
      </FlexContainer>

      <JourneyItem
        v-for="journey in journeys"
        :key="journey.id"
        :journey="journey"
      />
    </Content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import JourneyItem from '@/components/journeys/JourneyItem.vue'

export default {
  components: { JourneyItem },
  computed: {
    ...mapGetters({
      journeys: 'journeys/getJourneys',
      journeysMetadata: 'journeysMetadata/getJourneysMetadata'
    })
  },
  mounted() {
    this.$store.dispatch('journeysMetadata/openDBChannel')
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style lang="scss" scoped>
.journeys__header {
  flex-direction: column-reverse;
  position: relative;
  overflow-x: clip;

  @include respond-above(md) {
    flex-direction: row;
  }
}

.journeys__header-image {
  width: 100%;
  margin-bottom: $spacing-m;

  @include respond-above(md) {
    width: auto;
    position: absolute;
    bottom: 30%;
  }
}
</style>
