<template>
  <FlexContainer v-if="journey" padding-vertical="normal">
    <FlexItem padding="normal" class="journey__image">
      <h2>{{ journey.name }}</h2>
      <ImageItem
        :src="journey.imagePath"
        width="290px"
        height="290px"
        margin="0 auto"
      />
    </FlexItem>
    <FlexItem padding="normal" :grow="true">
      <Card type="quaternary">
        <FlexContainer>
          <FlexItem size="60%" padding-horizontal="normal">
            <h3>{{ journey.subtitle }}</h3>
            <div class="journey__description" v-html="journey.description" />
            <ButtonRouterLink
              type="success"
              :to="`/journeys/${journey.id}/levels`"
            >
              Start this journey
            </ButtonRouterLink>
          </FlexItem>
          <FlexItem padding="normal" size="40%" class="journey__stats">
            <div class="journey__stats">
              <div class="journey__stats-item">
                <Pill type="primary">{{ journey.pointsAvailable }}pts</Pill>
                available
              </div>
              <div class="journey__stats-item">
                <Pill type="tertiary">£{{ journey.savings }}+</Pill>
                savings
              </div>
              <div class="journey__stats-item">
                <Pill type="secondary">£{{ journey.taxRelief }}+</Pill>
                tax relief
              </div>
            </div>
          </FlexItem>
        </FlexContainer>
      </Card>
    </FlexItem>
  </FlexContainer>
</template>

<script>
export default {
  props: {
    journey: {
      type: Object,
      default: () => {}
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.journey__image {
  position: relative;
}

h2 {
  position: absolute;
  background: $color-white;
  border-radius: $border-radius;
  left: 50%;
  bottom: 15%;
  padding: $spacing-s $spacing-m;
  font-size: 2.4rem;
  text-align: center;
  transform: translateX(-50%);
}

h3 {
  font-size: 2.4rem;
  margin-bottom: $margin;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-align: left;
  text-decoration: none;
}

.journey__description {
  font-weight: 100;
}

.journey__stats {
  display: flex;
  padding: $spacing-m 0;

  .journey__stats-item {
    width: 33.3%;
    font-size: 14px;
    text-align: center;
    padding: 0 $spacing-s;

    button {
      width: 90%;
      margin-bottom: 0.4rem;
    }
  }
}
</style>
